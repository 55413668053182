import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';
import RHFPhoneInput from 'src/components/hook-form/rhf-phoneInput';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import { useSnackbar } from 'notistack';
import { useGetUser, useUpdateUser } from 'src/api/user';
import { useAuthContext } from 'src/auth/hooks';

export default function UserInfoVerification() {
  const verificationDialog = useBoolean(false);
  const [errorMsg, setErrorMsg] = useState('');

  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuthContext();
  const { isLoading, data: user } = useGetUser();
  const updateUser = useUpdateUser();

  useEffect(() => {
    if (!isLoading && !user?.phoneNo) {
      verificationDialog.onTrue();
    } else {
      verificationDialog.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoading]);

  const UserInfoVerificationSchema = Yup.object().shape({
    phoneNo: Yup.string().required(t('auth.phone-number-is-required')),
  });

  const defaultValues = {
    phoneNo: '',
  };

  const methods = useForm({
    resolver: yupResolver(UserInfoVerificationSchema),
    defaultValues,
  });

  const {
    // reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateUser.mutateAsync({
        phoneNo: data.phoneNo,
      });
      enqueueSnackbar(t('settings.user-details-updated-successfully'), { variant: 'success' });
    } catch (error) {
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  // Unauthenticated
  if (!auth.user) {
    return null;
  }

  return (
    <Dialog open={verificationDialog.value}>
      <DialogTitle>{t('submissions.please-provide-your-phone-number')}</DialogTitle>
      <DialogContent sx={{ pb: 2 }}>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack spacing={2.5}>
            {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
            <RHFPhoneInput name="phoneNo" label={t('submissions.phone-number')} />

            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              {t('common.submit')}
            </LoadingButton>
          </Stack>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
