import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
// import CompactLayout from 'src/layouts/compact';
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));

const ForgotPasswordPage = lazy(() => import('src/pages/auth/forgotPassword'));
const NewPasswordPage = lazy(() => import('src/pages/auth/newPassword'));

const authJwt = {
  path: 'jwt',
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout>
          <JwtRegisterPage />
        </AuthClassicLayout>
      ),
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </GuestGuard>
    ),
    children: [
      {
        path: 'forgotPassword',
        element: (
          <AuthModernCompactLayout>
            <ForgotPasswordPage />
          </AuthModernCompactLayout>
        ),
      },
      {
        path: 'newpassword',
        element: (
          <AuthModernCompactLayout>
            <NewPasswordPage />
          </AuthModernCompactLayout>
        ),
      },
      authJwt,
    ],
  },
];
