import { Box, Typography, Button } from '@mui/material';
import { Stack } from '@mui/system';
import { bgGradient } from 'src/theme/css';
import { useTheme, alpha } from '@mui/material/styles';
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import { useLocales } from 'src/locales';
import useUserLayoutContext from './userLayoutContext';

export default function Header() {
  const theme = useTheme();
  const { title } = useUserLayoutContext();
  const { t } = useLocales();

  return (
    <Box
      sx={{
        // backgroundColor: 'red',

        ...bgGradient({
          direction: '135deg',
          startColor: alpha(theme.palette.primary.light, 0.2),
          endColor: alpha(theme.palette.primary.main, 0.2),
        }),
        height: 290,
        color: 'primary.darker',
      }}
    >
      <Stack
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        spacing={2}
        height="80%"
      >
        <Typography variant="h4">{title}</Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          px: 3,
          maxWidth: '1200px',
          mx: 'auto',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          href={paths.submission.root}
        >
          {t('submissions.submit-new-bag')}
        </Button>
        <Button
          variant="contained"
          color="info"
          startIcon={<Iconify icon="mdi:cog" />}
          href={paths.user.settings}
        >
          {t('settings.settings')}
        </Button>
      </Stack>
    </Box>
  );
}
