import MainLayout from '../main/layout';
import Header from './header';
import { UserLayoutContextProvider } from './userLayoutContext';

type Props = {
  children: React.ReactNode;
};
export default function UserLayout({ children }: Props) {
  return (
    <MainLayout>
      <UserLayoutContextProvider>
        <Header />
        {children}
      </UserLayoutContextProvider>
    </MainLayout>
  );
}
