import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  lighter: '#F8E4CD',
  light: '#D99467',
  main: '#812D10',
  dark: '#5C1108',
  darker: '#3D0304',
  contrastText: '#FFFFFF',
};

const SECONDARY = {
  lighter: '#839fa8',
  light: '#6a8a95',
  main: '#37545d',
  dark: '#2a4149',
  darker: '#1a2c32',
  contrastText: '#FFFFFF',
};

const INFO = {
  lighter: '#D0E9FC',
  light: '#71AFF1',
  main: '#1963D3',
  dark: '#0C3897',
  darker: '#041B65',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#E6FBD5',
  light: '#9DE77F',
  main: '#3EB22C',
  dark: '#168018',
  darker: '#085516',
  contrastText: '#ffffff',
};

const WARNING = {
  lighter: '#FBFACB',
  light: '#EAE763',
  main: '#BCB607',
  dark: '#878203',
  darker: '#5A5601',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FBDFD0',
  light: '#E78670',
  main: '#AF1C1A',
  dark: '#7D0D1E',
  darker: '#53041F',
  contrastText: '#FFFFFF',
};

const COMMON = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.2),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export function palette(mode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: GREY[200],
    },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  };

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.12),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  };

  return mode === 'light' ? light : dark;
}
