import { TextFieldProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';

type Props = TextFieldProps & {
  name: string;
};

export default function RHFPhoneInput({ name, label, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{ validate: matchIsValidTel }}
      render={({ field, fieldState }) => (
        <MuiTelInput
          {...field}
          preferredCountries={['MY']}
          helperText={fieldState.invalid ? 'Your phone number is invalid' : ''}
          error={fieldState.invalid}
        />
      )}
    />
  );
}
