import { createContext, useContext, useMemo, useState } from 'react';

const userLayoutContext = createContext({
  title: 'Welcome',
  setTitle: (title: string) => {},
});

const useUserLayoutContext = () => useContext(userLayoutContext);

export default useUserLayoutContext;

type Props = {
  children: React.ReactNode;
};

export function UserLayoutContextProvider({ children }: Props) {
  const [title, setTitle] = useState('Welcome');

  const contextValue = useMemo(() => ({ title, setTitle }), [title, setTitle]);

  return <userLayoutContext.Provider value={contextValue}>{children}</userLayoutContext.Provider>;
}
