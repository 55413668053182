import { UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axiosInstance, { endpoints } from 'src/utils/axios';
import { IAddress } from 'src/types/address';
import { IUser } from 'src/types/user';

const QUERY_KEY = 'user';

export function useGetUser() {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async (): Promise<IUser> => {
      const { data } = await axiosInstance.get(endpoints.auth.me);
      return data.data;
    },
  });
}

export function useUpdateUser() {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: Pick<IUser, 'phoneNo' | 'name' | 'bankAccount' | 'bankAccountNumber'>) => {
      const response = await axiosInstance.post(endpoints.auth.me, data);
      return response.data.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY]);
      },
    }
  );
}

export function useUpdateAddress() {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: IAddress) => {
      const response = await axiosInstance.patch(endpoints.user.address, data);
      return response.data.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY]);
      },
    }
  );
}

export function useAdminUpdateUser(userId: string) {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { address: IAddress }) => axiosInstance.patch(`${endpoints.user.admin}/${userId}`, data),
    {
      onSuccess: ({ data }: { data: IUser }) => {
        queryClient.invalidateQueries([QUERY_KEY, 'admin', userId]);
      },
    }
  );
}

export function useVerifyAccount() {
  const queryClient = useQueryClient();

  return useMutation(
    async (image: File) => {
      const formData = new FormData();
      formData.append('image', image, image.name);

      return axiosInstance.post(endpoints.user.verify, formData, {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY]);
      },
    }
  );
}

export function useAdminGetVerificationImage(
  userId: string,
  option: Omit<UseQueryOptions<string, unknown, string, any>, 'queryFn' | 'queryKey'>
) {
  return useQuery({
    queryKey: [QUERY_KEY, 'admin', 'verification', userId],
    queryFn: async (): Promise<string> => {
      const { data } = await axiosInstance.get(`${endpoints.user.admin}/${userId}/verifyImage`);
      return data.data;
    },
    ...option,
  });
}

export function useAdminVerifyUser(userId: string) {
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      axiosInstance.patch(`${endpoints.user.admin}/${userId}`, {
        verified: 'verified',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY]);
      },
    }
  );
}

export function useAdminGetUser(
  userId: string,
  option?: Omit<UseQueryOptions<IUser, unknown, IUser, string[]>, 'initialData' | 'queryFn'>
) {
  return useQuery({
    queryKey: [QUERY_KEY, 'admin', userId],
    queryFn: async (): Promise<IUser> => {
      const { data } = await axiosInstance.get(`${endpoints.user.admin}/${userId}`);
      return data.data;
    },
    ...option,
  });
}
