import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useParticipateCollectionEvent } from 'src/api/collection-event';
import { useCreateImage } from 'src/api/image';
import { useCreateSellRequest } from 'src/api/sell-request';
import { ISellRequestForm } from 'src/types/sellRequest';

const submissionContext = createContext<{
  activeStep: number;
  form: ISellRequestForm;
  isCollectionEvent: boolean;
  collectionEventId: string;
  timeslotId: string;
  setIsCollectionEvent: Dispatch<SetStateAction<boolean>>;
  setCollectionEventId: Dispatch<SetStateAction<string>>;
  setTimeslotId: Dispatch<SetStateAction<string>>;
  handleNext: () => void;
  handleBack: () => void;
  setData: (data: Partial<ISellRequestForm>) => void;
  addInspection: (inspectArea: string, images: any[]) => void;
  setActiveStep: Dispatch<SetStateAction<number>>;
  delInspection: (inspectArea: string) => void;
  setAccessories: (accessories: string[]) => void;
  submit: UseMutationResult<unknown, unknown, void, unknown> | undefined;
  clearState: () => void;
}>({
  activeStep: 0,
  form: {
    material: '',
    inspections: [],
  },
  isCollectionEvent: false,
  collectionEventId: '',
  timeslotId: '',

  handleNext(): void {
    throw new Error('Function not implemented.');
  },
  handleBack(): void {
    throw new Error('Function not implemented.');
  },
  setData(): void {
    throw new Error('Function not implemented.');
  },
  addInspection(_inspectArea: string, _images: any[]): void {
    throw new Error('Function not implemented.');
  },
  setActiveStep(_value: SetStateAction<number>): void {
    throw new Error('Function not implemented.');
  },
  delInspection(_inspectArea: string): void {
    throw new Error('Function not implemented.');
  },
  submit: undefined,
  setAccessories(accessories: string[]): void {
    throw new Error('Function not implemented.');
  },
  setCollectionEventId(value: SetStateAction<string>): void {
    throw new Error('Function not implemented.');
  },
  setTimeslotId(value: SetStateAction<string>): void {
    throw new Error('Function not implemented.');
  },
  clearState(): void {
    throw new Error('Function not implemented.');
  },
  setIsCollectionEvent(value: SetStateAction<boolean>): void {
    throw new Error('Function not implemented.');
  },
});

const useSubmission = () => useContext(submissionContext);

export default useSubmission;

type Props = {
  children: React.ReactNode;
};

export function SubmissionProvider({ children }: Props) {
  const createImage = useCreateImage();
  const createSellRequest = useCreateSellRequest();
  const { enqueueSnackbar } = useSnackbar();
  const [isCollectionEvent, setIsCollectionEvent] = useState(false);
  const [collectionEventId, setCollectionEventId] = useState('');
  const [timeslotId, setTimeslotId] = useState('');
  const [, setSearchParams] = useSearchParams();

  const participateCollectionEvent = useParticipateCollectionEvent();

  const expiredTimer = useRef<NodeJS.Timeout>();

  const [activeStep, setActiveStep] = useState(0);
  const [form, setForm] = useState<ISellRequestForm>({ material: '', inspections: [] });
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setData = useCallback(
    (data: Partial<ISellRequestForm>) => {
      setForm({
        ...form,
        ...data,
      });
    },
    [form]
  );

  const addInspection = useCallback(
    (inspectArea: string, images: any[]) =>
      setForm({
        ...form,
        inspections: [
          ...form.inspections.filter((inspection) => inspection.inspectArea !== inspectArea),
          {
            inspectArea,
            images,
          },
        ],
      }),
    [form]
  );

  const delInspection = useCallback(
    (inspectArea: string) => {
      setForm({
        ...form,
        inspections: form.inspections.filter(
          (inspection) => inspection.inspectArea !== inspectArea
        ),
      });
    },
    [form]
  );

  const setAccessories = useCallback(
    (accessories: string[]) => {
      const accessoriesObj: {
        [key: string]: boolean;
      } = {};

      accessories.map((accessory: string) => {
        accessoriesObj[accessory] = true;
        return true;
      });

      setForm({
        material: form.material,
        inspections: form.inspections,
        ...accessoriesObj,
      });
    },
    [form.inspections, form.material]
  );

  const submit = useMutation(
    async () => {
      const formData = { ...form };
      formData.inspections = await Promise.all(
        form.inspections.map(async (inspection) => ({
          inspectArea: inspection.inspectArea,
          images: await Promise.all(
            inspection.images.map(async (image) => {
              const imageResponse = await createImage.mutateAsync(image);
              return imageResponse.data.data.id;
            })
          ),
        }))
      );
      const sellRequest = await createSellRequest.mutateAsync({ ...formData, isCollectionEvent });

      if (isCollectionEvent) {
        try {
          await participateCollectionEvent.mutateAsync({
            collectionEventId,
            timeslotId,
            sellRequestId: sellRequest.id,
          });
        } catch (error) {
          enqueueSnackbar('Your timeslot is taken, please reselect another timeslot', {
            variant: 'warning',
          });
          setActiveStep(-1);
          return;
        }
      }

      setSearchParams({});
      clearState();
      setActiveStep(3);
    },
    {
      onError: () => {
        enqueueSnackbar('Failed to submit your request, please try again later', {
          variant: 'error',
        });
      },
    }
  );

  const clearState = useCallback(() => {
    setForm({
      material: '',
      inspections: [],
    });
    setIsCollectionEvent(false);
    setCollectionEventId('');
    setTimeslotId('');
    setActiveStep(0);

    if (expiredTimer.current) clearTimeout(expiredTimer.current);
  }, []);

  useEffect(() => {
    if (timeslotId.length > 0) {
      expiredTimer.current = setTimeout(() => {
        enqueueSnackbar('Your submission has expired', {
          variant: 'error',
        });

        clearState();
      }, 5 * 60 * 1000);
    }
  }, [clearState, enqueueSnackbar, timeslotId]);

  const contextValue = useMemo(
    () => ({
      activeStep,
      handleNext,
      handleBack,
      form,
      setData,
      addInspection,
      setActiveStep,
      delInspection,
      submit,
      setAccessories,
      isCollectionEvent,
      setIsCollectionEvent,
      collectionEventId,
      setCollectionEventId,
      timeslotId,
      setTimeslotId,
      clearState,
    }),
    [
      activeStep,
      addInspection,
      collectionEventId,
      delInspection,
      form,
      isCollectionEvent,
      setAccessories,
      setData,
      submit,
      timeslotId,
      clearState,
    ]
  );

  return <submissionContext.Provider value={contextValue}>{children}</submissionContext.Provider>;
}
