import { lazy } from 'react';
import { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { RoleBasedGuard } from 'src/auth/guard';
import UserLayout from 'src/layouts/user';

const UserMainPage = lazy(() => import('src/pages/user'));
const UserBagSubmissionDetails = lazy(() => import('src/pages/user/bagSubmissionDetails'));
const SettingsPage = lazy(() => import('src/pages/user/settings'));

export const userRoutes: RouteObject[] = [
  {
    path: '/user',
    element: (
      <RoleBasedGuard roles={['admin', 'user']}>
        <UserLayout>
          <Outlet />
        </UserLayout>
      </RoleBasedGuard>
    ),
    children: [
      {
        index: true,
        element: <UserMainPage />,
      },
      {
        path: 'submission/:id',
        element: <UserBagSubmissionDetails />,
      },
      {
        path: 'settings',
        element: <SettingsPage />,
      },
    ],
  },
];
