import { useMutation } from '@tanstack/react-query';
import axiosInstance, { endpoints } from 'src/utils/axios';

export function useCreateImage() {
  return useMutation(async (image: File) => {
    const formData = new FormData();
    formData.append('image', image, image.name);

    return axiosInstance.post(`${endpoints.image}/upload`, formData, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    });
  });
}
