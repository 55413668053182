import {
  QueryOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { IFilter, IPagination } from 'src/types/filter';
import {
  ISellRequest,
  ISellRequestAcceptForm,
  ISellRequestAdminDetail,
  ISellRequestAdminForm,
  ISellRequestCount,
  ISellRequestDetail,
  ISellRequestDetailWithCreator,
  ISellRequestForm,
  ISellRequestIndicationForm,
} from 'src/types/sellRequest';
import axiosInstance, { endpoints } from 'src/utils/axios';

const QUERY_KEY = 'sellRequest';

export function useCreateSellRequest() {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: ISellRequestForm) => {
      const response = await axiosInstance.post(endpoints.sellRequest, data);

      return response.data.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY]);
      },
    }
  );
}

export function useGetSellRequestCount() {
  return useQuery({
    queryKey: [QUERY_KEY, 'count'],
    queryFn: async (): Promise<ISellRequestCount> => {
      const { data } = await axiosInstance.get(`${endpoints.sellRequest}/count`);

      return data.data;
    },
  });
}

export function useGetSellRequests(params?: IFilter, options?: QueryOptions) {
  return useQuery({
    queryKey: [QUERY_KEY, params],
    queryFn: async (): Promise<{ data: ISellRequest[]; pagination: IPagination }> => {
      const { data } = await axiosInstance.get(endpoints.sellRequest, { params });

      return data;
    },
    ...{ options },
  });
}

export function useGetSellRequest(id: string, params?: IFilter) {
  return useQuery({
    queryKey: [QUERY_KEY, id, params],
    queryFn: async (): Promise<ISellRequestDetail> => {
      const { data } = await axiosInstance.get(`${endpoints.sellRequest}/${id}`, { params });

      return data.data;
    },
  });
}

export function useAcceptPriceRange(requestId: string, isAdminUpdate: boolean) {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: ISellRequestAcceptForm) => {
      const response = await axiosInstance.patch(`${endpoints.sellRequest}/${requestId}/accept`, {
        ...data,
        locationId: data.locationId.toString(),
      });

      return response.data.data;
    },
    {
      onSuccess: () => {
        if (isAdminUpdate) {
          queryClient.invalidateQueries([QUERY_KEY, 'admin', requestId]);
        } else {
          queryClient.invalidateQueries([QUERY_KEY, requestId]);
        }
      },
    }
  );
}

export function useCancelSellRequest(requestId: string) {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const response = await axiosInstance.patch(`${endpoints.sellRequest}/${requestId}/cancel`);

      return response.data.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY]);
      },
    }
  );
}

export function useGetConsignmentNote(
  requestId: string,
  options?: Omit<UseQueryOptions<string, unknown, string, string[]>, 'queryKey' | 'queryFn'>
) {
  return useQuery({
    queryKey: [QUERY_KEY, requestId, 'consignmentNote'],
    queryFn: async (): Promise<string> => {
      const { data } = await axiosInstance.get(
        `${endpoints.sellRequest}/${requestId}/consignmentNote`,
        { responseType: 'blob' }
      );

      return data;
    },

    ...options,
  });
}

export function useIndicateBagForConsignmentOrSell(requestId: string) {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: ISellRequestIndicationForm) => {
      const response = await axiosInstance.patch(
        `${endpoints.sellRequest}/${requestId}/indicate`,
        data
      );

      return response.data.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY]);
      },
    }
  );
}

export function useAdminGetSellRequests(params?: IFilter, options?: QueryOptions) {
  return useQuery({
    queryKey: [QUERY_KEY, params],
    queryFn: async (): Promise<{
      data: ISellRequestDetailWithCreator[];
      pagination: IPagination;
    }> => {
      const { data } = await axiosInstance.get(`${endpoints.sellRequest}/admin`, { params });

      return data;
    },
    ...{ options },
  });
}

export function useAdminGetSellRequestCount(filter: string) {
  return useQuery({
    queryKey: [QUERY_KEY, 'admin', 'count', filter],
    queryFn: async (): Promise<number> => {
      const { data } = await axiosInstance.get(`${endpoints.sellRequest}/admin/count`, {
        params: {
          filter,
        },
      });

      return data.data;
    },
  });
}

export function useAdminGetOneSellRequest(
  id: string,
  params?: any,
  option?: Omit<
    UseQueryOptions<ISellRequestAdminDetail, unknown, ISellRequestAdminDetail, string[]>,
    'querFn'
  >
) {
  return useQuery({
    queryKey: [QUERY_KEY, 'admin', id, params],
    queryFn: async (): Promise<ISellRequestAdminDetail> => {
      const { data } = await axiosInstance.get(`${endpoints.sellRequest}/admin/${id}`, {
        params,
      });

      return data.data;
    },
    ...option,
  });
}

export function useAdminEditSellRequest(id: string) {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: ISellRequestAdminForm) => {
      const response = await axiosInstance.put(`${endpoints.sellRequest}/admin/${id}`, data);

      return response.data.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY, 'admin', id]);
      },
    }
  );
}
