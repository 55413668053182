import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { paths } from '../paths';

// Sell Request
const SellRequestListPage = lazy(() => import('src/pages/dashboard/sellRequest/list'));
const SellRequestDetailPage = lazy(() => import('src/pages/dashboard/sellRequest/detail'));
// Collection Events
const CollectionEventsListPage = lazy(() => import('src/pages/dashboard/collectionEvents/list'));
const CollectionEventsDetailPage = lazy(
  () => import('src/pages/dashboard/collectionEvents/detail')
);
// Users
const UsersListPage = lazy(() => import('src/pages/dashboard/users/list'));
const UsersDetailPage = lazy(() => import('src/pages/dashboard/users/detail'));

// easystore
const EasyStoreOrdersListPage = lazy(() => import('src/pages/dashboard/easystoreOrder/list'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'requests',
        children: [
          {
            element: <SellRequestListPage />,
            index: true,
          },
          {
            path: ':id',
            element: <SellRequestDetailPage />,
          },
        ],
      },
      {
        path: 'collection-events',
        children: [
          {
            element: <CollectionEventsListPage />,
            index: true,
          },
          {
            path: ':id',
            element: <CollectionEventsDetailPage />,
          },
        ],
      },
      {
        path: 'users',
        children: [
          {
            element: <UsersListPage />,
            index: true,
          },
          {
            path: ':id',
            element: <UsersDetailPage />,
          },
        ],
      },
      {
        path: 'easystore-orders',
        children: [
          {
            element: <EasyStoreOrdersListPage />,
            index: true,
          },
        ],
      },
      { index: true, element: <Navigate to={paths.dashboard.sellRequests.root} replace /> },
    ],
  },
];
