import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  sellRequest: '/sell-requests',
  brand: '/brands',
  collectionEvent: '/collection-events',
  easystoreOrder: {
    orders: '/easystore/orders',
    count: '/easystore/orders/count',
    ship: '/easystore/orders/ship',
  },
  users: '/users',
  image: '/images',
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  user: {
    address: '/users/address',
    verify: '/users/verify',
    admin: '/users/admin',
  },
  gdex: {
    getPostcodeLocation: '/gdex/getPostcodeLocations',
    getPickupDate: '/gdex/getPickUpDateListing',
    getPickupTimeslots: '/gdex/getPickUpTimeSlots',
  },
  auth: {
    me: '/users/profile',
    login: '/auth/login',
    facebookLogin: '/auth/login/facebook',
    facebookLoginWithToken: '/auth/login/facebook/token',
    googleLogin: '/auth/login/google',
    googleLoginWithToken: '/auth/login/google/token',
    register: '/auth/signup',
    resetPassword: '/auth/passwordreset',
    newPassword: '/auth/resetPassword',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
