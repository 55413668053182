import { UseMutationOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ICollectionEvent, ICollectionForm } from 'src/types/collectionEvent';
import axios, { endpoints } from 'src/utils/axios';

const QUERY_KEY = 'collection-events';

export const useGetCollectionEvents = () =>
  useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async (): Promise<ICollectionEvent[]> => {
      const { data } = await axios.get(endpoints.collectionEvent);
      // format date
      return data.data.map((event: { date: string | number | Date; timeslots: any[] }) => ({
        ...event,
        date: new Date(event.date),
        timeslots: event.timeslots.map(
          (timeslot: { startTime: string | number | Date; endTime: string | number | Date }) => ({
            ...timeslot,
            startTime: new Date(timeslot.startTime),
            endTime: new Date(timeslot.endTime),
          })
        ),
      }));
    },
  });

export const useHoldTimeslot = (
  options?: Omit<
    UseMutationOptions<any, unknown, { collectionEventId: string; timeslotId: string }, unknown>,
    'mutationFn'
  >
) =>
  useMutation(
    async ({
      collectionEventId,
      timeslotId,
    }: {
      collectionEventId: string;
      timeslotId: string;
    }) => {
      const response = await axios.patch(
        `${endpoints.collectionEvent}/${collectionEventId}/timeslot/${timeslotId}/hold`
      );
      return response.data.message;
    },
    options
  );

export const useGetParticipatedCollectionEvents = () =>
  useQuery({
    queryKey: [QUERY_KEY, 'participated'],
    queryFn: async (): Promise<ICollectionEvent[]> => {
      const { data } = await axios.get(`${endpoints.collectionEvent}/participated`);
      return data.data.map((event: { date: string | number | Date; timeslots: any[] }) => ({
        ...event,
        date: new Date(event.date),
        timeslots: event.timeslots.map(
          (timeslot: { startTime: string | number | Date; endTime: string | number | Date }) => ({
            ...timeslot,
            startTime: new Date(timeslot.startTime),
            endTime: new Date(timeslot.endTime),
          })
        ),
      }));
    },
  });

export const useParticipateCollectionEvent = () =>
  useMutation(
    async ({
      collectionEventId,
      timeslotId,
      sellRequestId,
    }: {
      collectionEventId: string;
      timeslotId: string;
      sellRequestId: string;
    }) =>
      axios.patch(
        `${endpoints.collectionEvent}/${collectionEventId}/timeslot/${timeslotId}/participate`,
        {
          sellRequest: sellRequestId,
        }
      )
  );

export const useAdminGetCollectionEvents = () =>
  useQuery({
    queryKey: [QUERY_KEY, 'admin'],
    queryFn: async (): Promise<ICollectionEvent[]> => {
      const { data } = await axios.get(`${endpoints.collectionEvent}/admin`);
      // format date
      return data.data.map((event: { date: string | number | Date; timeslots: any[] }) => ({
        ...event,
        date: new Date(event.date),
        timeslots: event.timeslots.map(
          (timeslot: { startTime: string | number | Date; endTime: string | number | Date }) => ({
            ...timeslot,
            startTime: new Date(timeslot.startTime),
            endTime: new Date(timeslot.endTime),
          })
        ),
      }));
    },
  });

export function useAdminCreateCollectionEvent() {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: ICollectionForm) => {
      const response = await axios.post(`${endpoints.collectionEvent}/admin`, data);
      return response.data.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY, 'admin']);
      },
    }
  );
}

export function useAdminDeleteCollectionEvent() {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: string) => {
      const response = await axios.delete(`${endpoints.collectionEvent}/admin/${id}`);
      return response.data.message;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY, 'admin']);
      },
    }
  );
}
