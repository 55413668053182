// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  submission: {
    root: '/submission',
    submissionWithEvent: (eventId: string) => `/submission?collectionEvent=${eventId}`,
  },
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',

  user: {
    profile: '/user',
    submissionDetail: (id: string) => `/user/submission/${id}`,
    settings: '/user/settings',
  },

  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',

  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/jwt/login`,
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
    forgotPassword: `${ROOTS.AUTH}/forgotPassword`,
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    sellRequests: {
      root: `${ROOTS.DASHBOARD}/requests`,
      view: (id: string) => `${ROOTS.DASHBOARD}/requests/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/requests/${id}?mode=edit`,
    },
    collectionEvents: {
      root: `${ROOTS.DASHBOARD}/collection-events`,
      view: (id: string) => `${ROOTS.DASHBOARD}/collection-events/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/collection-events/${id}?mode=edit`,
      new: `${ROOTS.DASHBOARD}/collection-events/new`,
    },
    users: {
      root: `${ROOTS.DASHBOARD}/users`,
      view: (id: string) => `${ROOTS.DASHBOARD}/users/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/users/${id}?mode=edit`,
    },
    easystoreOrders: {
      root: `${ROOTS.DASHBOARD}/easystore-orders`,
    },
  },
};
